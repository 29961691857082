import React, { useContext, useState } from 'react';

import { NavbarContext } from '../context/NavbarContext';
import { NavbarChaptersContainer } from '../styles/Navbar.styles';
import {
  NavbarChapterContainer,
  NavbarChapterHeading,
  NavbarChapterTop,
} from '../styles/NavbarChapter.styles';

import { NavbarChapter } from './NavbarChapter';

/**
 * @type {React.FC<{
 *   chapters: import('../types').ChaptersType;
 *   wrapper: object;
 * }>}
 */
export const NavbarChapters = ({ chapters = [], wrapper }) => {
  const [activeSection, setActiveSection] = useState(chapters[0]?.title ?? null);
  const { linkComponent, isShowMobileMenu } = useContext(NavbarContext);

  const onSectionSelect = (id) => () => {
    activeSection === id ? setActiveSection(null) : setActiveSection(id);
  };

  return (
    <NavbarChaptersContainer $isShowMobileMenu={isShowMobileMenu}>
      {chapters.map((chapter) =>
        chapter.sections?.length > 0 ? (
          <NavbarChapter
            key={chapter.title}
            title={chapter.title}
            sections={chapter.sections}
            isOpen={activeSection === chapter.title}
            wrapper={wrapper}
            onSectionSelect={onSectionSelect(chapter.title)}
          />
        ) : (
          <NavbarChapterContainer
            as={linkComponent}
            key={chapter.title}
            href={chapter.url}
            data-analytics={chapter.dataAnalytics}
          >
            <NavbarChapterTop as="div">
              <NavbarChapterHeading>{chapter.title}</NavbarChapterHeading>
            </NavbarChapterTop>
          </NavbarChapterContainer>
        )
      )}
    </NavbarChaptersContainer>
  );
};
