import { css } from 'styled-components';

export const changelogStyles = css`
  position: relative;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    right: -8px;
    width: 6px;
    height: 6px;
    background-color: rgb(238, 85, 103);
    border-radius: 8px;
  }
`;
