import styled, { css } from 'styled-components';
import { Button } from '../../button';

export const NavbarButton = styled(Button)(
  ({ theme: { desktop } }) => css`
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    min-width: 80px;
    height: 32px;

    &:last-child {
      margin-right: 0;
    }

    ${desktop()} {
      height: 38px;
    }
  `
);

const navbarContentButtonsBasicStyles = css`
  border-top: none;
  padding: 0;
  background-color: transparent;
  height: auto;
`;

export const NavbarContentButtons = styled.div(
  ({ $isBasic, theme: { tablet, laptop, desktop, isDark } }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid ${isDark ? '#191d20' : '#eeeeee'};
    background: ${isDark ? '#000' : '#fff'};
    padding: 15px 15px;

    ${NavbarButton} {
      flex-grow: 1;
    }

    ${$isBasic &&
    css`
      ${navbarContentButtonsBasicStyles};
      padding-right: 5px;
    `}

    ${tablet()} {
      ${navbarContentButtonsBasicStyles}
      ${$isBasic &&
      css`
        padding-right: 7px;
      `}
    }

    ${laptop()} {
      padding: 0;
      margin-left: 20px;
    }

    ${desktop()} {
      margin-left: 35px;
    }
  `
);
