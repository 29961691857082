import { useEffect, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';

const CHANGELOG_VISITED_DATES_KEY = 'changelog-visited-dates';
// change date if you want to highlight changelog in site/docs
const LAST_CHANGELOG_UPDATE = '2022-04-01';

export const useChangelogUpdate = () => {
  const [isChangelogVisited, setIsChangelogVisited] = useState(true);
  const [dates, setDates] = useLocalStorageState(CHANGELOG_VISITED_DATES_KEY, {
    ssr: true,
    defaultValue: [],
  });

  const visitChangelog = () => {
    if (!dates.includes(LAST_CHANGELOG_UPDATE)) {
      setDates([...dates, LAST_CHANGELOG_UPDATE]);
    }
  };

  useEffect(() => {
    setIsChangelogVisited(dates && dates.includes(LAST_CHANGELOG_UPDATE));
  }, [dates]);

  return {
    isChangelogVisited,
    visitChangelog,
    dates,
  };
};
