import { useEffect, useRef, useState } from 'react';

export const useResizeObserver = (element) => {
  const observer = useRef(null);
  const [observerEntry, setObserverEntry] = useState({});

  useEffect(() => {
    if (typeof window.ResizeObserver !== 'undefined') {
      observer.current = new window.ResizeObserver(([entry]) => setObserverEntry(entry));

      return () => {
        element && observer.current.unobserve(element);
      };
    }
  }, [observer, element]);

  useEffect(() => {
    element && observer.current?.observe(element);
  }, [element]);

  return observerEntry;
};
