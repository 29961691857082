import styled, { css } from 'styled-components';

export const NavbarMainContent = styled.div(
  ({ theme: { isDark } }) => css`
    width: 100%;
    height: var(--navbar-height);
    background-color: ${isDark ? 'rgba(0, 0, 0, 0.75)' : 'rgba(255, 255, 255, 0.95)'};
    transition: background-color 0.2s ease-in-out;

    @supports (backdrop-filter: none) {
      background-color: ${isDark ? 'rgba(0, 0, 0, 0.75)' : 'rgba(255, 255, 255, 0.88)'};
      backdrop-filter: blur(10px);
    }
  `
);
