import { useCallback, useEffect, useState } from 'react';

export const useThemeChange = ({ ref, shrinkLogo = false, initialTheme, fallbackTheme }) => {
  const [isScrolled, setScrolled] = useState(false);
  const [theme, setTheme] = useState(initialTheme);
  const [themeSections, setThemeSections] = useState([]);

  const handleChangeTheme = useCallback(() => {
    const navbarBounding = ref.current.getBoundingClientRect();
    const intersectingSection = themeSections.find(
      (section) =>
        section.getBoundingClientRect().top <= navbarBounding.top &&
        section.getBoundingClientRect().bottom > 0
    );

    setTheme(intersectingSection?.dataset?.navbarTheme ?? fallbackTheme);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [themeSections, fallbackTheme]);

  const handleScroll = useCallback(() => {
    const scrolled = window.scrollY;

    if (scrolled > 0) {
      // in safari, scroll can be negative
      handleChangeTheme();
    }

    if (shrinkLogo) {
      const navbarHeight = ref.current?.clientHeight;
      scrolled > navbarHeight ? setScrolled(true) : setScrolled(false);
    }
  }, [setScrolled, ref, shrinkLogo, handleChangeTheme]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    setThemeSections(Array.from(document.querySelectorAll('[data-navbar-theme]')));
  }, []);

  return {
    theme,
    isScrolled,
  };
};
