import React, { useState, useEffect, useRef } from 'react';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';
import { ThemeProvider } from 'styled-components';

import {
  Logo,
  LogoCircle,
  LogoText,
  Nav,
  NavbarContent,
  NavWrapper,
  OpenContentButton,
  OpenContentButtonLine,
  FocusLockStyled,
  GlobalNavStyles,
} from './styles/Navbar.styles';
import { NavbarMainContent } from './styles/NavbarMainContent.styles';

import { useCurrentBreakpoint, BreakpointEnum } from '../use-current-breakpoint';
import { data as defaultData } from './data';
import { NavbarButtons, NavbarChapters } from './components';
import { useThemeChange } from './hooks/useThemeChange';
import { ThemesEnum } from '../theme';
import { NavbarProvider } from './context/NavbarContext';

/**
 * @type {React.FC<{
 *   className?: string;
 *   data?: {
 *     buttons?: import('./types').ButtonsType;
 *     chapters?: import('./types').ChaptersType;
 *   };
 *   linkComponent?: React.FC | string;
 *   shrinkLogo?: boolean;
 *   isAuthenticated?: boolean;
 *   initialTheme?: string;
 *   scrollNoIsolation?: boolean;
 * }>}
 */
export const Navbar = ({
  className,
  data = defaultData,
  linkComponent = 'a',
  shrinkLogo = true,
  isAuthenticated = true,
  initialTheme,
  scrollNoIsolation,
}) => {
  const [isShowMobileMenu, setShowMobileMenu] = useState(false);

  const navbarRef = useRef(null);
  const contentContainerRef = useRef(null);
  const currentBreakpoint = useCurrentBreakpoint();
  const { theme, isScrolled } = useThemeChange({
    ref: navbarRef,
    shrinkLogo,
    initialTheme,
  });

  const isBasicMode = data.chapters?.length === 0;

  useEffect(() => {
    if (currentBreakpoint >= BreakpointEnum.LG) {
      setShowMobileMenu(false);
    }
  }, [currentBreakpoint]);

  return (
    <>
      <GlobalNavStyles />
      <Nav className={className} ref={navbarRef}>
        <ThemeProvider theme={{ isDark: theme === ThemesEnum.DARK }}>
          <NavbarMainContent>
            <NavWrapper>
              <NavbarProvider
                linkComponent={linkComponent}
                isShowMobileMenu={isShowMobileMenu}
                isAuthenticated={isAuthenticated}
              >
                <Logo as={linkComponent} href="/" aria-label="Logo" $isScrolled={isScrolled}>
                  <LogoText />
                  <LogoCircle />
                </Logo>

                <FocusLock disabled={!isShowMobileMenu} as={FocusLockStyled}>
                  <RemoveScroll
                    ref={contentContainerRef}
                    enabled={isShowMobileMenu}
                    forwardProps
                    noIsolation={scrollNoIsolation}
                  >
                    <NavbarContent $isBasic={isBasicMode} $isShowMobileMenu={isShowMobileMenu}>
                      {data.chapters.length > 0 && (
                        <NavbarChapters
                          chapters={data.chapters}
                          wrapper={contentContainerRef.current}
                        />
                      )}

                      <NavbarButtons buttons={data.buttons} isBasic={isBasicMode} />
                    </NavbarContent>
                  </RemoveScroll>
                </FocusLock>

                {!isBasicMode && (
                  <OpenContentButton
                    onClick={() => setShowMobileMenu((isShowMenu) => !isShowMenu)}
                    $isCross={isShowMobileMenu}
                    aria-label="Toggle menu"
                  >
                    <OpenContentButtonLine />
                    <OpenContentButtonLine />
                    <OpenContentButtonLine />
                  </OpenContentButton>
                )}
              </NavbarProvider>
            </NavWrapper>
          </NavbarMainContent>
        </ThemeProvider>
      </Nav>
    </>
  );
};
