import styled, { css } from 'styled-components';
import { changelogStyles } from '../../use-changelog-update';

import { ReactComponent as IconArrowLink } from '../assets/arrow.svg';
import { ReactComponent as LineIconLink } from '../assets/line.svg';

export const ArrowIcon = styled(IconArrowLink)`
  display: block;
`;

export const LineIcon = styled(LineIconLink)`
  display: none;
`;

export const NavbarChapterTop = styled.button(
  ({ theme: { laptop, isDark } }) => css`
    font-family: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 12px var(--container-gutter-x, 15px);
    width: 100%;
    height: max-content;
    color: ${isDark ? '#ffffff' : '#000000'};
    background-color: transparent;
    box-sizing: border-box;
    border: none;
    outline: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover,
    &:focus-visible {
      background-color: ${isDark ? '#202225' : '#f3f4f6'};
      outline: none;
    }

    ${laptop()} {
      position: relative;
      color: ${isDark ? '#A1AFBC' : '#596570'};
      padding: 0;
      z-index: 10;

      &:hover,
      &:focus-visible {
        color: ${isDark ? '#ffffff' : '#000000'};
        background-color: transparent;
      }
    }
  `
);

export const NavbarChapterHeading = styled.span(
  ({ href, theme: { laptop } }) => css`
    ${href &&
    css`
      text-decoration: none;
      pointer-events: none;
    `};

    color: inherit;
    font-weight: 700;
    font-size: 17px;
    line-height: 1.2;
    white-space: nowrap;

    ${laptop()} {
      font-weight: 500;
      font-size: 15px;
      line-height: 1.7;
    }
  `
);

export const NavbarChapterBottom = styled.div(
  ({ $maxHeight, $offset = 0, theme: { laptop } }) => css`
    max-height: ${$maxHeight};
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    @supports (overflow: clip) {
      overflow: clip;
    }

    ${laptop()} {
      position: absolute;
      top: 0;
      left: ${`${$offset}px`};
      padding-top: 36px;
      max-height: none;
      visibility: hidden;
      opacity: 0;
      overflow: visible;
      z-index: 0;
      transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out,
        visibility 0.3s ease-in-out;
      transition-delay: 0.2s;

      &:focus-within {
        opacity: 1;
        pointer-events: auto;
      }
    }
  `
);

export const NavbarChapterContent = styled.div(
  ({ theme: { tablet, laptop, isDark } }) => css`
    background-color: ${isDark ? '#202225' : '#f3f4f6'};
    padding: 0 6px;

    ${tablet()} {
      padding: 0 calc(var(--container-gutter-x, 15px) - 14px);
    }

    ${laptop()} {
      display: flex;
      flex-wrap: wrap;
      padding: 6px;
      box-shadow: ${isDark
        ? '0 1px 2px rgba(0, 0, 0, 0.25), 0 4px 22px rgba(0, 0, 0, 0.7)'
        : '0 1px 4px rgba(0, 0, 0, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1)'};
      border-radius: 8px;
      width: max-content;
      box-sizing: border-box;
      background-color: ${isDark ? '#131619' : '#ffffff'};
    }
  `
);

export const NavbarChapterSection = styled.div(
  ({ theme: { laptop } }) => css`
    display: flex;
    flex-direction: column;
    padding: 6px 0;

    ${laptop()} {
      padding: 0;
    }
  `
);

export const NavbarSectionHeading = styled.span(
  ({ theme: { isDark } }) => css`
    font-weight: 700;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${isDark ? '#ffffff' : '#000000'};
    padding: 10px 14px;
  `
);

export const NavbarChapterIcon = styled.i(
  ({ theme: { laptop } }) => css`
    width: 13px;

    ${laptop()} {
      width: 8px;
      margin-left: 2px;
    }
  `
);

export const NavbarChapterContainer = styled.div(
  ({ $isOpen, theme: { laptop, isDark } }) => css`
    position: relative;
    border-style: solid;
    border-width: 1px 0 0 0;
    border-color: ${isDark ? '#191d20' : '#eeeeee'};
    text-decoration: none;
    color: inherit;
    outline: none;
    display: flex;
    flex-direction: column;

    &:last-child {
      border-width: 1px 0 1px 0;
    }

    ${$isOpen &&
    css`
      ${ArrowIcon} {
        display: none;
      }

      ${LineIcon} {
        display: block;
      }
    `}

    ${laptop()} {
      border: none;
      margin-right: 30px;

      ${ArrowIcon} {
        display: block;
      }

      ${LineIcon} {
        display: none;
      }

      &:hover {
        color: ${isDark ? '#ffffff' : '#000000'};

        ${ArrowIcon} {
          display: none;
        }

        ${LineIcon} {
          display: block;
        }

        ${NavbarChapterBottom} {
          opacity: 1;
          visibility: visible;
        }
      }

      &:last-child {
        margin-right: 0;
        border: none;
      }
    }
  `
);

export const NavbarChapterLink = styled.a(
  ({ theme: { laptop, isDark } }) => css`
    width: 100%;
    padding: 10px 25px 10px 14px;
    text-decoration: none;
    outline: none;
    color: ${isDark ? '#8895a1' : '#596570'};
    line-height: 1.25;

    background-color: transparent;
    border-radius: 5px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

    &:hover,
    &:focus-visible {
      color: ${isDark ? '#ffffff' : '#000000'};
      background-color: ${isDark ? '#2a2f33' : '#e6e9eb'};
      outline: none;
    }

    ${laptop()} {
      max-width: 250px;

      &:hover,
      &:focus-visible {
        background-color: ${isDark ? '#2a2f33' : '#f3f4f6'};
      }
    }
  `
);

export const NavbarLinkText = styled.span(
  ({ theme: { laptop } }) => css`
    font-weight: 500;
    font-size: 15px;

    ${laptop()} {
      font-weight: 500;
    }
  `
);

export const NavbarChangelogLink = styled(NavbarChapterLink)(
  ({ isChangelogVisited = false }) => css`
    ${!isChangelogVisited &&
    css`
      ${NavbarLinkText} {
        ${changelogStyles}
      }
    `}
  `
);
