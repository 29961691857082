import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Navbar as CommonNavbar, data as defaultData } from '@uc-common/navbar';
import { ThemesEnum } from '@uc-common/theme';
import { useAuthContext } from '@uc-common/check-auth';

import { Link } from '../Link';
import { PPCData } from './PPCData';

export const NavbarModesEnum = {
  DEFAULT: 'default',
  PPC: 'PPC',
};

const HiddenDarkNavbar = styled.div.attrs({
  'data-navbar-theme': ThemesEnum.DARK,
})`
  /* hack to make the navbar dark */
  margin-top: calc(var(--navbar-height) * -1);
  padding-top: var(--navbar-height);
  background: #000;
`;

export const Navbar = ({ mode = NavbarModesEnum.DEFAULT, data, isLight = false }) => {
  const { user } = useAuthContext();
  const { isDark } = useTheme();
  const isAuthenticated = !!user;
  const navbarData = data ?? (mode === NavbarModesEnum.PPC ? PPCData : defaultData);

  return (
    <>
      <CommonNavbar
        linkComponent={({ href, ...props }) => <Link {...props} to={href} />}
        data={navbarData}
        isAuthenticated={isAuthenticated}
        initialTheme={isLight ? undefined : isDark ? ThemesEnum.DARK : undefined}
      />
      {!isLight && isDark && <HiddenDarkNavbar />}
    </>
  );
};
