import React from 'react';
import { NavbarChangelogLink } from './styles/NavbarChapter.styles';
import { withChangelogData } from '../use-changelog-update';

const NavbarLinkWithChangelogData = withChangelogData(NavbarChangelogLink);

export const data = {
  buttons: {
    defaultButtons: [
      {
        text: 'Log in',
        url: '/accounts/login/',
        dataAnalytics: 'btn_top-menu_login',
      },
      {
        text: (
          <>
            <b>Get started</b> — free
          </>
        ),
        url: '/accounts/signup/',
        dataAnalytics: 'btn_top-menu_signup',
      },
    ],
    authenticatedButton: {
      text: 'Dashboard',
      url: '/dashboard/',
      dataAnalytics: 'btn_top-menu_dashboard',
    },
  },
  chapters: [
    {
      title: 'Platform',
      url: '/features/',
      dataAnalytics: 'btn_top-menu_features',
    },
    {
      title: 'Uploader',
      url: '/products/file-uploader/',
      dataAnalytics: 'btn_top-menu_file-uploader',
    },
    {
      title: 'Pricing',
      url: '/pricing/',
      dataAnalytics: 'btn_top-menu_pricing',
    },
    {
      title: 'Developers',
      sections: [
        {
          links: [
            {
              title: 'Quick Start',
              url: '/docs/start/quickstart/',
              target: '_blank',
              dataAnalytics: 'btn_top-menu_docs_quick-start',
            },
            {
              title: 'Documentation',
              url: '/docs/',
              target: '_blank',
              dataAnalytics: 'btn_top-menu_docs_documentation',
            },
            {
              title: 'Integrations',
              url: '/docs/integrations/',
              target: '_blank',
              dataAnalytics: 'btn_top-menu_docs_integrations',
            },
            {
              title: 'API',
              url: '/api/',
              dataAnalytics: 'btn_top-menu_api',
            },
            {
              as: NavbarLinkWithChangelogData,
              title: 'Changelog',
              url: '/docs/changelog/',
              target: '_blank',
              dataAnalytics: 'btn_top-menu_docs_changelog',
            },
            {
              title: 'Migration',
              url: '/docs/guides/migro/',
              target: '_blank',
              dataAnalytics: 'btn_top-menu_docs_migration',
            },
          ],
        },
      ],
    },
    {
      title: 'Company',
      sections: [
        {
          links: [
            {
              title: 'Blog',
              url: '/blog/',
              dataAnalytics: 'btn_top-menu_blog',
            },
            {
              title: 'About us',
              url: '/company/',
              dataAnalytics: 'btn_top-menu_company',
            },
            {
              title: 'Customers',
              url: '/customers/',
              dataAnalytics: 'btn_top-menu_customers',
            },
            {
              title: 'Partners',
              url: '/partners/',
              dataAnalytics: 'btn_top-menu_partners',
            },
            {
              title: 'Compliance',
              url: '/about/trust/',
              target: '_blank',
              dataAnalytics: 'btn_top-menu_about_trust',
            },
            {
              title: 'Contact us',
              url: '/schedule-demo/',
              target: '_blank',
              dataAnalytics: 'btn_top-menu_schedule-demo',
            },
          ],
        },
      ],
    },
  ],
};
