import styled, { createGlobalStyle, css } from 'styled-components';

import { ReactComponent as LogoTextIcon } from '../assets/logo-text.svg';
import { ReactComponent as LogoCircleIcon } from '../assets/logo-circle.svg';

import { Container as PageContainer } from '../../container';
import { NavbarContentButtons } from './NavbarButtons.styles';

export const GlobalNavStyles = createGlobalStyle(
  ({ theme: { tablet, laptop } }) => css`
    :root {
      --navbar-height: 42px;

      ${tablet()} {
        --navbar-height: 46px;
      }

      ${laptop()} {
        --navbar-height: 58px;
      }
    }
  `
);

export const Nav = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const NavWrapper = styled(PageContainer)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const NavbarContentBasic = css`
  position: static;
  top: 0;
  width: auto;
  display: flex;
  flex-direction: row;
  opacity: 1;
  pointer-events: auto;
  transform: none;
  height: auto;
  align-items: center;
  background-color: transparent;
  overflow: visible;

  ${NavbarContentButtons} {
    position: static;
    order: 0;
  }
`;

export const NavbarContent = styled.div(
  ({ $isShowMobileMenu = false, $isBasic, theme: { tablet, isDark } }) => css`
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: max-content;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: calc(100vh - var(--navbar-height));
    background-color: ${isDark ? '#000000' : '#ffffff'};
    /* this overflow causes a weird rendering behaviour in chrome when content height is less than container height
  https://uploadcare.atlassian.net/browse/WEB-1222 №16
   */
    /* overflow-y: auto; */
    transition: opacity 0.3s ease, transform 0.3s ease;

    ${NavbarContentButtons} {
      position: sticky;
      z-index: 10;
      top: 0;
      left: 0;
      order: -1;
    }

    ${$isShowMobileMenu
      ? css`
          opacity: 1;
          transform: translateY(0px);
        `
      : css`
          opacity: 0;
          pointer-events: none;
          transform: translateY(10px);
        `}

    ${$isBasic && `${NavbarContentBasic}`}

  ${tablet()} {
      ${NavbarContentBasic}
    }
  `
);

export const LogoText = styled(LogoTextIcon)(
  ({ theme: { tablet, laptop, desktop, isDark } }) => css`
    width: 89px;
    transition: opacity 0.3s ease;
    color: ${isDark ? '#ffffff' : '#000000'};

    ${tablet()} {
      width: 99px;
    }

    ${laptop()} {
      width: 93px;
    }

    ${desktop()} {
      width: 100px;
    }
  `
);

export const LogoCircle = styled(LogoCircleIcon)(
  ({ theme: { tablet, desktop } }) => css`
    width: 18px;
    margin-left: 4px;
    flex-shrink: 0;
    transition: transform 0.5s ease;

    ${tablet()} {
      width: 20px;
    }

    ${desktop()} {
      width: 22px;
    }
  `
);

export const Logo = styled.a(
  ({ $isScrolled, theme: { tablet, laptop, desktop } }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;

    ${LogoText} {
      opacity: ${$isScrolled ? '0' : '1'};
    }

    ${LogoCircle} {
      transform: ${$isScrolled ? 'translateX(-93px)' : 'translateX(0)'};

      ${tablet()} {
        transform: ${$isScrolled ? 'translateX(-103px)' : 'translateX(0)'};
      }

      ${laptop()} {
        transform: ${$isScrolled ? 'translateX(-98px)' : 'translateX(0)'};
      }

      ${desktop()} {
        transform: ${$isScrolled ? 'translateX(-103px)' : 'translateX(0)'};
      }
    }

    &:hover,
    &:focus-visible {
      ${LogoText} {
        opacity: 1;
      }

      ${LogoCircle} {
        transform: translateX(0);
      }
    }
  `
);

export const OpenContentButtonLine = styled.span(
  ({ theme: { isDark } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 2px;
    background-color: ${isDark ? '#838f9a' : '#9da9b5'};
    transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;

    &:nth-child(2) {
      top: 5px;
    }

    &:nth-child(3) {
      top: unset;
      bottom: 1px;
    }
  `
);

export const OpenContentButton = styled.button(
  ({ $isCross, theme: { tablet, laptop, isDark } }) => css`
    position: relative;
    background: none;
    width: 22px;
    height: 13px;
    border: none;
    cursor: pointer;
    padding: 0;
    outline: none;

    &:hover,
    &:focus-visible {
      ${OpenContentButtonLine} {
        background-color: ${isDark ? '#ffffff' : '#000000'};
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 30px;
    }

    ${OpenContentButtonLine} {
      &:first-child {
        transform: ${$isCross ? 'translateY(5px) rotate(45deg)' : 'inherit'};
      }

      &:nth-child(2) {
        opacity: ${$isCross ? '0' : '1'};
      }

      &:nth-child(3) {
        transform: ${$isCross ? 'translateY(-5px) rotate(-45deg)' : 'inherit'};
      }
    }

    ${tablet()} {
      margin-left: 20px;
    }

    ${laptop()} {
      display: none;
    }
  `
);

export const NavbarChaptersContainer = styled.div(
  ({ $isShowMobileMenu = false, theme: { tablet, laptop, isDark } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${isDark ? '#000000' : '#ffffff'};
    color: ${isDark ? '#838f9a' : '#000000'};

    ${tablet()} {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: calc(100vh - var(--navbar-height));
      overflow: auto;
      opacity: ${$isShowMobileMenu ? 1 : 0};
      pointer-events: ${$isShowMobileMenu ? 'auto' : 'none'};
      transform: ${$isShowMobileMenu ? 'translateY(0)' : 'translateY(10px)'};
      transition: opacity 0.3s ease, transform 0.3s ease;
    }

    ${laptop()} {
      position: relative;
      top: auto;
      flex-direction: row;
      align-items: center;
      opacity: 1;
      transform: translateY(0px);
      transition: none;
      pointer-events: auto;
      background-color: transparent;
      height: auto;
      color: #596570;
      overflow: visible;
    }
  `
);

export const FocusLockStyled = styled.div`
  margin-left: auto;
`;
