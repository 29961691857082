import React, { useRef, useState, useEffect, useContext } from 'react';

import {
  NavbarChapterIcon,
  NavbarChapterContainer,
  NavbarChapterLink,
  NavbarChapterBottom,
  NavbarChapterContent,
  NavbarChapterSection,
  NavbarChapterTop,
  NavbarChapterHeading,
  NavbarSectionHeading,
  NavbarLinkText,
  ArrowIcon,
  LineIcon,
} from '../styles/NavbarChapter.styles';

import { useResizeObserver } from '../../use-resize-observer';
import { useCurrentBreakpoint, BreakpointEnum } from '../../use-current-breakpoint';
import { NavbarContext } from '../context/NavbarContext';

/**
 * @type {React.FC<{
 *   title: string;
 *   sections: {
 *     title: string;
 *     links: {
 *       title: string;
 *       url: string;
 *       target: string;
 *       dataAnalytics: string;
 *       image: string;
 *     }[];
 *   }[];
 *   isOpen: boolean;
 *   onSectionSelect: () => void;
 *   wrapper: object;
 * }>}
 */
export const NavbarChapter = ({
  title,
  sections = [],
  isOpen = false,
  onSectionSelect,
  wrapper,
}) => {
  const [offset, setOffset] = useState(0);
  const [contentMaxHeight, setContentMaxHeight] = useState('none');

  const chapterContentRef = useRef(null);
  const wrapperResizeObserver = useResizeObserver(wrapper);
  const currentBreakpoint = useCurrentBreakpoint();
  const isDesktopView = currentBreakpoint >= BreakpointEnum.LG;
  const { linkComponent } = useContext(NavbarContext);

  useEffect(() => {
    if (!chapterContentRef) return;

    isOpen
      ? setContentMaxHeight(`${chapterContentRef.current.scrollHeight}px`)
      : setContentMaxHeight('0px');
  }, [isOpen]);

  useEffect(() => {
    if (!wrapperResizeObserver || !wrapperResizeObserver.target) return;

    if (!isDesktopView) {
      setOffset(0);
      return;
    }

    const wrapperRightBounding = wrapperResizeObserver.target.getBoundingClientRect().right;
    const chapterContentRightBounding = chapterContentRef.current.getBoundingClientRect().right;

    if (
      chapterContentRightBounding > wrapperRightBounding ||
      (chapterContentRightBounding < wrapperRightBounding && offset !== 0)
    ) {
      setOffset(Math.ceil(wrapperRightBounding - chapterContentRightBounding + offset));
    }
  }, [wrapperResizeObserver, offset, isDesktopView]);

  return (
    <NavbarChapterContainer $isOpen={isOpen}>
      <NavbarChapterTop onClick={onSectionSelect} htmlType="button">
        <NavbarChapterHeading>{title}</NavbarChapterHeading>
        <NavbarChapterIcon>
          <LineIcon />
          <ArrowIcon />
        </NavbarChapterIcon>
      </NavbarChapterTop>
      <NavbarChapterBottom ref={chapterContentRef} $offset={offset} $maxHeight={contentMaxHeight}>
        <NavbarChapterContent>
          {sections.map((section, index) => (
            <NavbarChapterSection key={index}>
              {section.title && <NavbarSectionHeading>{section.title}</NavbarSectionHeading>}
              {section.links.map((link) => (
                <NavbarChapterLink
                  key={link.title}
                  href={link.url}
                  target={link.target}
                  data-analytics={link.dataAnalytics}
                  as={linkComponent}
                  $iconSrc={link.image}
                >
                  <NavbarLinkText>{link.title}</NavbarLinkText>
                </NavbarChapterLink>
              ))}
            </NavbarChapterSection>
          ))}
        </NavbarChapterContent>
      </NavbarChapterBottom>
    </NavbarChapterContainer>
  );
};
