import React, { useContext } from 'react';
import { useTheme } from 'styled-components';

import { NavbarContext } from '../context/NavbarContext';
import { NavbarButton, NavbarContentButtons } from '../styles/NavbarButtons.styles';
import { ButtonTypeEnum } from '../../button';

/**
 * @type {React.FC<{
 *   buttons: import('../types').ButtonsType;
 *   isBasic?: boolean;
 * }>}
 */
export const NavbarButtons = ({ buttons, isBasic = false }) => {
  const { isDark } = useTheme();
  const { defaultButtons, authenticatedButton } = buttons;
  const { linkComponent, isAuthenticated } = useContext(NavbarContext);
  const hasButtons =
    (defaultButtons.length > 0 && !isAuthenticated) || (authenticatedButton && isAuthenticated);

  return (
    hasButtons && (
      <NavbarContentButtons $isBasic={isBasic}>
        {isAuthenticated ? (
          <NavbarButton
            key="authenticatedButton"
            forwardedAs={linkComponent}
            href={authenticatedButton.url}
            type={isDark ? ButtonTypeEnum.TRANSPARENT : ButtonTypeEnum.DARK}
            data-analytics={authenticatedButton.dataAnalytics}
          >
            {authenticatedButton.text}
          </NavbarButton>
        ) : (
          <>
            <NavbarButton
              forwardedAs={linkComponent}
              href={defaultButtons[0].url}
              type={isDark ? ButtonTypeEnum.TRANSPARENT : ButtonTypeEnum.DARK}
              ghost={defaultButtons.length > 1}
              data-analytics={defaultButtons[0].dataAnalytics}
            >
              {defaultButtons[0].text}
            </NavbarButton>

            {defaultButtons[1] && (
              <NavbarButton
                forwardedAs={linkComponent}
                href={defaultButtons[1].url}
                type={ButtonTypeEnum.PRIMARY}
                data-analytics={defaultButtons[1].dataAnalytics}
              >
                {defaultButtons[1].text}
              </NavbarButton>
            )}
          </>
        )}
      </NavbarContentButtons>
    )
  );
};
