import React, { createContext, useMemo } from 'react';

export const NavbarContext = createContext({
  linkComponent: 'a',
});

export const NavbarProvider = ({
  linkComponent,
  isShowMobileMenu = false,
  isAuthenticated = false,
  children,
}) => {
  const value = useMemo(
    () => ({ linkComponent, isShowMobileMenu, isAuthenticated }),
    [linkComponent, isShowMobileMenu, isAuthenticated]
  );

  return <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>;
};
